import { reactive, computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import pull from 'lodash/pull';
import { Song } from '@/types/song';
import { useApi, AuthHeader } from '@/hooks/use-api';

const { client } = useApi();
const baseURL = '/api/songs/';

export interface SongsState {
    data: Array<Song>;
    error: string;
}

// Singleton
let songsState: SongsState;

export const useSongs = () => {
    songsState = songsState || reactive<SongsState>({
        data: [],
        error: ''
    });

    const songs = computed(() => songsState.data || []);

    const fetchSongs = async (): Promise<void> => {
        try {
            const res = await client.get(baseURL);

            if (res.status == 200 && res.data) {
                songsState.data = <Array<Song>>res.data;
            }
        } catch (error) {
            songsState.error = error;
        }
    }

    const getSongUrl = (song: Song, replace: boolean = false): RouteLocationRaw => {
        return <RouteLocationRaw>{
            name: "SongDetail",
            params: {
                id: song.short_id,
                title: song.title.trim().replace(/[^a-zA-Z0-9]/g, '-').replaceAll(/-+/g, '-').toLowerCase(),
                artist: song.artist.trim().replace(/[^a-zA-Z0-9]/g, '-').replaceAll(/-+/g, '-').toLowerCase()
            },
            replace: replace
        }
    }

    const addSong = async (song: Song): Promise<boolean> => {
        try {
            const res = await client.post(baseURL, song, { headers: AuthHeader() });
            if (res.status == 200 && res.data) {
                song = res.data;
                song.recentlyAdded = true;
                songsState.data.push(song);

                setTimeout(() => {
                    song.recentlyAdded = false;
                }, 5000);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            songsState.error = error;
            return false;
        }
    }

    const editSong = async (song: Song): Promise<boolean> => {
        try {
            const res = await client.put(baseURL + song._id, song, { headers: AuthHeader() });
            if (res.status == 200 && res.data) {
                let index = getSongIndex(song);
                songsState.data[index] = song;
                return true;
            } else {
                return false;
            }
        } catch (error) {
            songsState.error = error;
            return false;
        }
    }

    const getSong = (id: string): Song => {
        return songsState.data[getSongIndexById(id)];
    }

    const getSongIndex = (song: Song) : number => {
        return getSongIndexById(song.short_id);
    }

    const getSongIndexById = (id: string) : number => {
        return songsState.data.findIndex(x => x.short_id == id);
    }

    const deleteSong = async (song: Song): Promise<boolean> => {
        try {
            const res = await client.delete(baseURL + song._id, { headers: AuthHeader() });
            if(res.status == 200 && res.data) {
                pull(songsState.data, song);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            songsState.error = error;
            return false;
        }
    }

    return { songsState, songs, fetchSongs, addSong, editSong, getSong, deleteSong, getSongUrl };
}